/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: "MENU.APPS" })}</span>
        </div>
      </div>
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      <SidebarMenuItem
        to='/supplies/list'
        icon='package'
        title={intl.formatMessage({ id: "MENU.APPS.SUPPLIES" })}
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItemWithSub
        to='/products'
        title={intl.formatMessage({ id: "MENU.APPS.PRODUCTS" })}
        icon='lots-shopping'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/products/groups' title={intl.formatMessage({ id: "MENU.APPS.PRODUCTS.GROUPS" })} hasBullet={true} />
        <SidebarMenuItem to='/products/items' title={intl.formatMessage({ id: "MENU.APPS.PRODUCTS.ITEMS" })} hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItem
        to='/products/items'
        icon='lots-shopping'
        title={intl.formatMessage({ id: "MENU.APPS.PRODUCTS.ITEMS" })}
        fontIcon='bi-person'
      />
      <SidebarMenuItem
        to='/orders/list'
        icon='handcart'
        title={intl.formatMessage({ id: "MENU.APPS.ORDERS" })}
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={'/'}
          // href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}

export {SidebarMenuMain}
